/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from './seo';
import Layout from './layout';
import CODE from './MDX/CODE';
import H1 from './MDX/H1';
import H2 from './MDX/H2';
import H3 from './MDX/H3';
import P from './MDX/P';
import UL from './MDX/UL';

const BlogPostLayout = ({ children, pageContext }) => {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx {
          edges {
            node {
              frontmatter {
                title
                path
                date(formatString: "MMMM DD, YYYY")
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const { title, description, path } = pageContext.frontmatter;
  const ogUrl = `https://tomekdev.com/posts${path}`;
  const edge = allMdx.edges.find((e) => e.node.frontmatter.path === path);
  const post = edge ? edge.node.frontmatter : null;
  const imageUrl = post && post.image ? post.image.publicURL : null;

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        ogUrl={ogUrl}
        imageUrl={imageUrl}
        type="article"
      />

      <MDXProvider
        components={{
          code: CODE,
          h1: H1,
          h2: H2,
          h3: H3,
          p: P,
          ul: UL,
        }}
      >
        {children}
      </MDXProvider>
    </Layout>
  );
};

BlogPostLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.object,
};

export default BlogPostLayout;
